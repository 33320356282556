.publication-list {
    margin-top: 0.75rem;
}

.project-link {
    border-radius: 50px;
    background: white;
    display: inline-block;
    padding: 10px 15px;
    font-weight: bold;

    a {
        color: #4eaf47;
        &:hover {
            color: #0056b3;
        }
    }
}