.thumbnail {
    width: 200px;
    height: 250px;
}

.post-image {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

    > img {
      margin: 2px;
      max-width: 100%;
      @media (min-width: 992px) { 
        width: 60%;
      }
    }
  }


.thumbnail-image {
    max-width: 100%;
    height: auto;

}


.pb-100 {
	padding-bottom: 100px;
}
.pt-100 {
	padding-top: 100px;
}
.mb-100 {
	margin-bottom: 100px;
}
a {
	text-decoration: none;
	-webkit-transition: .4s;
	transition: .4s;
}
.section-title {
	position: relative;
}
.section-title p {
	font-size: 16px;
	margin-bottom: 5px;
	font-weight: 400;
}
.section-title h4 {
	font-size: 40px;
	font-weight: 600;
	text-transform: capitalize;
	position: relative;
	padding-bottom: 20px;
	display: inline-block;
}
.section-title h4::before {
	position: absolute;
	content: "";
	width: 80px;
	height: 2px;
	background-color: #d8d8d8;
	bottom: 0;
	left: 50%;
	margin-left: -40px;
}
.section-title h4::after {
	position: absolute;
	content: "";
	width: 50px;
	height: 2px;
	background-color: $sensebox-green;
	left: 0;
	bottom: 0;
	left: 50%;
	margin-left: -25px;
}
.blog-img {
	position: relative;
}
.blog-img img {
	width: 100%;
}
.post-category a {
	display: inline-block;
	background-color: $sensebox-green;
	color: #fff;
	font-size: 15px;
	padding: 5px 20px;
}
.single-blog:hover img {
	opacity: .85;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)"
}
.post-category {
	position: absolute;
	left: 0;
	bottom: 0;
}
.blog-content {
	padding: 30px 20px;
}
.single-blog {
	border: 1px solid #eee;
}
.blog-title h4 {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 5px;
}
.meta ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.meta {
	margin-bottom: 20px;
	opacity: .85;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
}
.blog-content a.box_btn {
	display: inline-block;
	background-color: $sensebox-green;
	padding: 5px 15px;
	color: #fff;
	text-transform: capitalize;
	margin-top: 20px;
}
a.box_btn::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-color: $sensebox-green;
	left: -100%;
	top: 0;
	-webkit-transition: .5s;
	transition: .5s;
	z-index: -1;
}
a.box_btn {
	overflow: hidden;
	z-index: 2;
	-webkit-transition: .5s;
	transition: .5s;
	position: relative;
	text-decoration:none;
}
a.box_btn:hover::before {
	left: 0;
	z-index: -1;
}









