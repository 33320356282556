ul {
  margin-top: 0.5rem;
  li {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

.btn-sensebox-red {
  background-color: rgb(252, 70, 43);
  color: white;
}

.btn-sensebox-green {
  background-color: rgb(67, 162, 56);
  color: white;
}

.btn-sensebox-orange {
  background-color: #f5b22f;
  color: white;
}

.btn-sensebox-blue {
  background-color: rgb(58, 176, 232);
  color: white;
}

.btn-sensebox-white {
  background-color: white;
  margin-bottom: 1rem;
}

.middle {
  margin-top: auto;
  margin-bottom: auto;

  .featurette-heading {
    margin: 0;
  }
}

.partner-card {
  display: block;
  border: none;
  .card-body {
    padding-top: 1.25rem;
    padding-left: 0px;
  }
}
