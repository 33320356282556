/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

/* Featurettes
  ------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}

/* RESPONSIVE CSS
  -------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.phenomenon-icon {
  display: grid;
  background: transparent;
  border: none;
}

.phenomenon-icon-img {
  width: 40px;
  height: 40px;
}

figure {
  margin: 0;
  position: relative;
}

figure img {
  padding-top: 4rem;
  max-width: 100%;
  display: block;
  position: relative;
  z-index: 10;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
}

figure:hover img {
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
  opacity: 0.1;
}

figure:hover figcaption {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  h3 {
    opacity: 0.8;
  }
  p,
  ul {
    opacity: 1;
    padding-top: 1em;
    line-height: 2;
    align-content: center;
  }
  a {
    opacity: 1;
  }
}

figure a {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

figcaption {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  padding: 20px;
  background: none;
  color: #ff8b45;

  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  -moz-transition: -moz-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;

  h3 {
    opacity: 0.7;
  }
  p,
  a,
  ul {
    opacity: 0;
  }
}

figcaption h3 {
  margin: 0;
  padding: 0;
  color: black;
}

.sensors-text {
  background: none;
}

.icon {
  text-align: center;
}

.featurette-heading {
  @media (max-width: $breakpoint-tablet) {
    padding-top: 1.5em;
  }
}

.phenos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1em 0;
  width: 100%;
}
.sb-pheno {
  margin-top: 1em;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.8em 0;

  @media (max-width: 767px) {
    // text-align: left;
    display: flex;
  }

  &.sb-small-pheno {
    width: 140px !important;
  }
}
.sb-pheno-bike {
  margin-top: 1em;
  margin-right: 10px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.8em 0;

  @media (max-width: 767px) {
    // text-align: left;
    display: flex;
  }

  &.sb-small-pheno {
    width: 140px !important;
  }
}

.comparisonTable td:nth-child(2) {
  text-align: right;
}
.comparisonTable td:nth-child(3) {
  text-align: right;
}

.table-subhead {
  color: $sensebox-green;
  font-family: BrixSlab-Bold;
  font-size: 1.2em;
}

.table-product-comparison .fa {
  color: $sensebox-green;
}

.news-items {
  padding: 0px 35px 0 35px;
}
