.hover-container {
    position: relative;

    img {
        border-radius: 0.5rem;
        transition: .5s ease;
    }

    &:hover img {
        filter: brightness(50%);
    }

    &:hover .middle {
        opacity: 1;
    }

    .middle {
        transition: 0.5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
    }
}
