/* Farbvariablen für senseBox-Design */
:root {
    --primary-color: #4da843;
    --primary-hover: #43a03a;
    --text-color: #333;
    --bg-color: #fff;
    --tab-inactive-bg: #f2f2f2;
    --tab-border-color: #e0e0e0;
    --shadow-color: rgba(0, 0, 0, 0.1);
    --transition-speed: 0.3s;
  }
  
  .tabs {
    margin-top: 20px;
    background: var(--bg-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px var(--shadow-color);
    overflow: hidden;
  }
  
  .tab-nav {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    background: var(--bg-color);
    justify-content: space-around; /* Sorgt für gleichmäßige Verteilung */
    border-bottom: 2px solid var(--tab-border-color);
  }
  
  .tab-nav li {
    flex: 1;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
    font-weight: 600;
    color: var(--text-color);
    background-color: var(--tab-inactive-bg);
    border-right: 1px solid var(--tab-border-color);
    transition: background-color var(--transition-speed), color var(--transition-speed);
  }
  
  .tab-nav li:last-child {
    border-right: none; /* Beim letzten Tab kein rechter Rand */
  }
  
  .tab-nav li:hover {
    background-color: #e9e9e9;
  }
  
  .tab-nav li.active {
    background-color: var(--primary-color);
    color: #fff;
    border-right: 1px solid var(--primary-color);
    padding: 10px;
  }
  
  .tab-content {
    background: var(--bg-color);
    padding: 20px;
  }
  
  .tab-pane {
    display: none;
  }
  
  .tab-pane.active {
    display: block;
  }
  