.hide-content{
display: none;
}

.align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important;
  }
  .d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
  }

.go-card-body {
  text-align: center;
}

.go-featurette {
  padding-top: 4rem;
  padding-bottom: 4rem;
}