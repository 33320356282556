.image-gallery {
    overflow: auto;
    margin-left: -1% !important;
}

.image-gallery li {
    float: left;
    display: block;
    margin: 0 0 1% 1%;
    width: 24%;
}
    
  @media screen and (max-width: 800px) {
    .image-gallery li {
        width: 45%;
      }
  }
  @media screen and (max-width: 500px) {
    .image-gallery li {
        width: 100%;
      }
  }
  

.image-gallery li {
    text-align: center;
    text-decoration: none !important;
    color: #777;
}

.image-gallery li span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 3px 0;
}

.image-gallery li img {
    width: 100%;
    display: block;
}