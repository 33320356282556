/* Events css
-------------------------------------------------- */

.event {
    padding: 20px;
    vertical-align: middle;
    display: flex;
  
    .details {
      margin-left: 20px;
    }
  }

.events .collapse.in, .collapsing {
    box-shadow: none;
    background-color: #ffffff;
  }
  
  .events-description {
    color: black;
  }
  
  .events {
  color: lightslategrey;
  font-family: Arial, sans-serif;
  font-size: initial;
  }
  
  .events .panel-heading {
    background-color: #4eaf4714;
    border-radius: 1rem;
    margin-bottom: 12px;
  
    > a {
        color: black;
    }
  }

  .events-row {
    display: flex;
    flex-wrap: wrap;
  
    .event-item {
      flex-grow: 1;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: 5px;
      margin-left: 5px;
    }
  
    .thumbnail {
      height: 100%;
    }
  }

  .panel {
    border: 0px solid transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .panel-default {
    border-color: transparent;
  
    > .panel-heading {
      background-color: transparent;
    }
  }
  
  .panel-group .panel-heading + .panel-collapse > .panel-body,
  .panel-group .panel-heading + .panel-collapse > .list-group {
    border-top: 0px solid transparent;
  }
  

  /* -----------------------------------------------
 * Timeline
 * --------------------------------------------- */
 .timeline-events {
  list-style: none;
  padding: 10px 0;
  position: relative;
  font-weight: 300;
}
.timeline-events:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content:" ";
  width: 4px;
  background: $sensebox-green;
  left: 50%;
  margin-left: -1.5px;
}
.timeline-events > li {
  margin-bottom: 20px;
  position: relative;
  width: 50%;
  float: left;
  clear: left;
}
.timeline-events > li:before, .timeline > li:after {
  content:" ";
  display: table;
}
.timeline-events > li:after {
  clear: both;
}
.timeline-events > li:before, .timeline > li:after {
  content:" ";
  display: table;
}
.timeline-events > li:after {
  clear: both;
}
.timeline-events > li > .timeline-panel {
  width: calc(100% - 25px);
  width: -moz-calc(100% - 25px);
  width: -webkit-calc(100% - 25px);
  float: left;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  position: relative;
}
.timeline-events > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #dcdcdc;
  border-right: 0 solid #dcdcdc;
  border-bottom: 15px solid transparent;
  content:" ";
}
.timeline-events > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #ffffff;
  border-right: 0 solid #ffffff;
  border-bottom: 14px solid transparent;
  content:" ";
}
.timeline-events > li > .timeline-badge {
  color: #ffffff;
  width: 24px;
  height: 24px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 16px;
  right: -12px;
  z-index: 100;
}
.timeline-events > li.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline-events > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline-events > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-badge > a {
  color: #ffffff !important;
}
.timeline-badge a:hover {
  color: #dcdcdc !important;
}
.timeline-title {
  margin-top: 0;
  color: inherit;
}
.timeline-heading h4 {
  font-weight: 400;
  padding: 10px 15px;
  color: #4679bd;
}
.timeline-body > p, .timeline-body > ul {
  padding: 10px 15px;
  margin-bottom: 0;
}
.timeline-footer {
  padding: 5px 15px;
  background-color:#f4f4f4;
}
.timeline-footer p { margin-bottom: 0; }
.timeline-footer > a {
  cursor: pointer;
  text-decoration: none;
}
.timeline-events > li.timeline-inverted {
  float: right;
  clear: right;
}
.timeline-events > li:nth-child(2) {
  margin-top: 60px;
}
.timeline-events > li.timeline-inverted > .timeline-badge {
  left: -12px;
}
.no-float {
  float: none !important;
}
@media (max-width: 767px) {
  ul.timeline:before {
      left: 40px;
  }
  ul.timeline-events > li {
      margin-bottom: 0px;
      position: relative;
      width:100%;
      float: left;
      clear: left;
  }
  ul.timeline-events > li > .timeline-panel {
      width: calc(100% - 80px);
      width: -moz-calc(100% - 80px);
      width: -webkit-calc(100% - 80px);
  }
  ul.timeline-events > li > .timeline-badge {
      left: 28px;
      margin-left: 0;
      top: 16px;
  }
  ul.timeline > li > .timeline-panel {
      float: right;
  }
  ul.timeline-events > li > .timeline-panel:before {
      border-left-width: 0;
      border-right-width: 15px;
      left: -15px;
      right: auto;
  }
  ul.timeline-events > li > .timeline-panel:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto;
  }
  .timeline-events > li.timeline-inverted {
      float: left;
      clear: left;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .timeline-events > li.timeline-inverted > .timeline-badge {
      left: 28px;
  }
}