$sensebox-green: #4eaf47;
$sensebox-blue: rgb( 58, 176, 232);
$sensebox-red: rgb(252, 70, 43);
$warning: orange;

$text-black: black;

$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1199px;
