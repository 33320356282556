

.item-inner{
    background-color: #f8f9fa;
    padding: 20px;
}

.main-container {
    padding-top: 3rem;
    h1 {
        padding-bottom: 1rem;
    }
}